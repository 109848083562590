const translationsAr = {
    "status": "success",
    "data": {
        "lang": "he",
        "app": "skygroup",
        "translation": {
            "upgradePlan": {
                "general": {
                    "upgrade_plan": "ترقية الخطة",
                    "upgrade_plan_to_regular": "ترقية إلى الخطة العادية",
                    "upgrade_plan_to_pro": "ترقية إلى خطة PRO",
                  },
                "regular": {
                    "title": "قم بالترقية الآن واحصل على الوصول إلى منتجات Agent 360!",
                    "benefits_title_text": "أنت حالياً مشترك في حزمة Agent 360 Light، إذا قمت بالترقية يمكنك الحصول على",
                    "text_1": "منتجات سكاي 360 بالجملة",
                    "text_2": "فنادق لجميع الوجهات حول العالم",
                    "text_3": "رحلات منتظمة ومنخفضة التكلفة",
                    "text_4": "أنشطة ترفيهية حول العالم",
                    "button_text": "قم بالترقية الآن إلى Agent 360!"
                },
                "pro": {
                    "title": "قم بالترقية الآن واحصل على موقع ويب لأعمالك مع كامل العلامة التجارية!",
                    "benefits_title_text": "في حزمة SKY 360 Pro يمكنك الاستمتاع بـ",
                    "text_1": "موقع ويب مع نطاق خاص بك",
                    "text_2": "علامة تجارية للأعمال - شعار وألوان الموقع",
                    "text_3": "الترويج للرحلات والعروض على الموقع حسب الوجهات",
                    "text_4": "جميع الحجوزات التي تتم ستكون ملكاً لوكالتك!",
                    "button_text": "قم بالترقية الآن إلى Pro!"
                }
            },
            "productPage":{
                "colors":['#32D6FF', '#3C41DF', '#51497A', '#82B6EC', '#FF6576', '#e566ff'],
                "header": {
                    "links":{
                        "product-header":'עמוד הבית',
                        "about-us":'מי אנחנו',
                        "product":'המוצרים שלנו',
                        "contact-us":'צור קשר',
                        "private-flights":"טיסות פרטיות",
                    },
                    "text":"תעופה ומערכות טכנולוגיות לעולם התיירות",
                    "privateFlightsText":"טיסות פרטיות מחיפה",
                    "button":"קרא עוד"
                },
                'product':{
                    "titleLarge":"המוצרים של Sky 360",
                    "titleSmall":"הכירו את המערכות החדשניות של עולם התיירות מקרוב",
                    "products":{
                        "skygroups":{
                            'logo':'logo-skygroups.svg',
                            'img':"IconSkyGroup.svg",
                            'name':"Sky Groups",
                            'texts':{
                                'c1':'מערכת לרישום קבוצות',
                                'c2':'הקמת דפי נחיתה עבור חבילת נופש - טיסה מלון העברות ואטרקציות',
                                'c22':'הקמת קבוצת עובדים עם סבסוד וזיהוי העובד',
                                'c3':"אפשרות לסליקה עם פיצול אשראי",
                                'c44':"ניהול הזמנות והפקת דוחות לספקים",
                                'c4':"ניהול הזמנות חכם וניהול דוחות ספקים ותשלומים",
                            }
                        },
                        "skyb2c":{
                            'logo':'logo-skyb2c.svg',
                            'img':"IconSkyb2c.svg",
                            'name':"Sky Web",
                            "texts":{
                                'c2':"אתר ללקוח הקצה המאפשר הצגה כלל המוצרים במגוון תבניות מכירה דינמית למלונות וטיסות וחבילות נופש",
                                'c1':"מנוע חיפוש חבילות ומלונות",
                                'c3':"קידום פרטני של מוצרים שונים",
                                'c4':"עיצוב דינמי וחדשני המותאם לעסק",
                                'c5':"מודול סליקת לקוח עם פיצול אשראי ועוד",
                            }
                        },
                        "sky360":{
                            'logo':'logo-sky360.svg',
                            'img':"IconSky360.svg",
                            "texts":{
                                'c1':'מערכת לניהול לקוחות בצורה אוטומטית אשר מאפשרת מענה למאות לקוחות מדי יום',
                                'c2':"גישה מהירה למידע עבור לקוחות באמצעות בוט ",
                                'c3':"מרכזיה חכמה כוללת תפריט קולי וניתוב לקוחות לוואטסאפ העסקי",
                                'c4':"ל בלקוח על ידי נציג אנושי 24/7. "
                            }
                        },
                        "skyacs":{
                            'logo':'logo-skyacs.svg',
                            'img':"IconSkyAcs.svg",
                            'name':"ACS",
                            "texts":{
                                "c1":"בוט ומענה אנושי לניהול לקוחות אוטומטי",
                                "c2":"מאפשרת מענה למאות לקוחות מדי יום",
                                "c3":"גישה מהירה למידע עבור לקוחות באמצעות בוט",
                                "c4":"מרכזיה חכמה - כוללת תפריט קולי וניתוב לקוחות לוואטסאפ העסקי",
                                "c5":"טיפול בלקוח על ידי נציג אנושי 24/7."
                            }
                        },
                        "skyacco":{
                            'logo':'logo-skyeco.svg',
                            'img':"IconSkyAcco.svg",
                            'name':"Sky Acco",
                            "texts":{
                                'c1':"מערכת ניהול למתחמי לינה - מלונות וצימרים",
                                'c2':'המערכת כוללת אתר ללקוח הקצה לרכישת לילות',
                                "c3":"עיצוב חדשני ומותאם לכל לקוח",
                                'c4':"במערכות ניהול מלאי חדרים, מחירונים ואטרקציות",
                                "c5":"אפשרות לייצר חבילות נופש ודילים לאירועים",
                                'c6':"אינטגרציה לאתר קופונים",
                            }
                        },

                        "skyagent":{
                            'logo':'logo-skyagent.svg',
                            'img':"IconSkyAgent.svg",
                            'name':"Sky Agent",
                            "texts":{
                                'c1':" מערכת למשווקים (קמעונאית עם אלפ)",
                                'c2':"מאפשרת הקמת חבילות משולבות טיסה מלון ואטרקציות",
                                'c3':"ניהול הזמנות ויצירת לידים",
                                'c4':"סליקת לקוח עם פיצול אשראי",
                                'c5':"אפשרות להקים אתר מכירתי למשווק",
                            }
                        },
                    },
                },
                'aboutUs':{
                    "rightTitle":"מי אנחנו",
                    'desc': "Sky 360 הינה חברה העוסקת בתעופה ופיתוח מערכות ענן הנותנות פתרונות טכנולוגיים בעולם התיירות. \n\n" +
                        "לחברה מספר מערכות חדשניות שהוקמו עקב צורך וכאב בעולם התיירות.  בין מוצרנו: מערכת לרישום קבוצות, הקמת אתרי שכר בתיירות, אתרי מלונות, מערכת סיטונאטית לניהול מלאי ועוד.",
                    'desc_flight': "בפעילות התעופה, חברתנו מתתחה בהפעלת טיסות ציארטר ליעדי אירופה והמזרח התיכון.\n" +
                        'הפעלת טיסות מיוחדות לבקשת הלקוח משדה התעופה נתב"ג ושדה התעופה חיפה. \n' +
                        "הפעלת טיסות מיוחדות לאירועי ספורט בעולם. \n" +
                        "מכירת קבוצות לועדים, ארגונים וחברות מובילות במשק. \n\n" +
                        "בשנת 2023 חברתנו החזירה לפעילות מסחרית את שדה התעופה חיפה לאחר 4 שנים ללא פעילות . \n" +
                        `בשנת 2023 חברתנו החזירה לפעילות מסחרית את שדה התעופה חיפה לאחר 4 שנים ללא פעילות .
                        חברתנו הפעילה בשיתוף חברת התעופה המלטזית יוניברסל אייר טיסות לקפריסין החל מ22.6 ועד ל8.10 , 3 טיסות ביום כל ימות השבוע (לא כולל שבת )כולל טיסות חילוץ לשגרירות קנדה בישראל בזמן המלחמה.
                        הטיסות בוצעו עם מטוסים צרי גוף מסוג Dash8 -Q100
                        המתאימים לפעילות במסלולים הקצרים של שדה התעופה חיפה .`,
                    'btn':"קרא עוד",
                    "title":"Sky 360",
                    // 'subTitle':"החברה מספקת פתרונות טכנולוגים מתקדמים לעולם התיירות  -סיטונאים ולקמעונאים בארץ ובעולם",
                    'subTitle':"לחברה פעילויות בתחום התעופה ופיתוח מערכות טכנולוגיות בעולם התיירות",
                },
                'contactUs':{
                    'title':"צור קשר",
                    'subTitle':"צרו איתנו קשר ונתאים עבורכם את המערכת המתאימה ביותר בשבילכם",
                    'email':"דוא״ל",
                    "textArea":"כתבו לנו הודעה",
                    "phone":"טלפון",
                    "fullName":"שם מלא",
                    'button':"שליחה",
                    'fieldRequired':"שדה נדרש"
                },
                "footer":{
                    'text':"כל הזכויות שמורות לsky360-. אין להשתמש בחומרי ותוכן האתר ללא אישור.",
                }
            },
            "trainingLandingPage": {
                "title": "تدريب وكلاء ومسوقي السياحة",
                "courseDetails": {
                    "when": {
                        "title": "موعد التدريب"
                    },
                    "structure": {
                        "title": "هيكل التدريب",
                        "semiTitle": "4 أيام دراسية",
                        "content": {
                            "c1": "الدرس 1 - التعرف على عالم السياحة وتحليل الجماهير والوجهات",
                            "c2": "الدرس 2 - استراتيجية التسويق والبيع من خلال الخدمة",
                            "c3": "الدرس 3 - الشبكات الاجتماعية، المؤثرين والجوانب القانونية",
                            "c4": "الدرس 4 - التعرف والتسجيل في نظام SKY 360 للوكلاء"
                        },
                        "bonus": {
                            "title": "بونص",
                            "content1": "الشهر الأول مجانًا لنظام SKY 360",
                            "content2": "للمسجلين في التدريب تحت وكالة المنزل"
                        }
                    },
                    "duty": {
                        "title": "ما المطلوب؟",
                        "content": "المشاركة بانتظام في المحاضرات، أداء المهام، وتقديم مشروع التخرج"
                    },
                    "promoteSection": {
                        "t1": "للعمل في عالم السياحة!",
                        "t2": "تعالوا للعمل في عالم السياحة مع دعم من الأفضل!"
                    }
                },
                "forWho": {
                    "title": "لمن مخصصة البرنامج؟",
                    "content": {
                        "c1": "للراغبين في توليد دخل إضافي أو أساسي في عالم السياحة",
                        "c2": "للراغبين في الاندماج كفريلانسر تحت SKY 360",
                        "c3": "وكلاء السفر الذين يرغبون في الانتقال إلى النظام الأكثر تقدمًا للبيع في عالم السياحة"
                    }
                },
                "finishSuccessfully": {
                    "title": "للمتخرجين بنجاح من التدريب",
                    "benefits": [
                        {
                            "title": "سيتم منح شهادة وكيل ومسوق سياحي من شركة SKY360",
                            "img": "certification.png"
                        },
                        {
                            "title": "شهر تجريبي مجاني في نظام SKY360",
                            "img": "check_mark.png"
                        },
                        {
                            "title": "مرافقة مستمرة مع فريق الخبراء في السنة الأولى حتى النجاح",
                            "img": "check_mark.png"
                        }
                    ]
                },
                "payment": {
                    "title": "تكلفة التدريب",
                    "shah": "شيكل",
                    "content1": "التسجيل للتدريب ينتهي قريبا",
                    "content2": "عدد الأماكن محدود",
                    "registerNow": "سجل الآن!",
                    "form": {
                        "title": "سجل الآن!",
                        "full_name": "الاسم الكامل",
                        "email": "البريد الإلكتروني",
                        "phone": "الهاتف",
                        "birthday": "تاريخ الولادة",
                        "moreInfo": "هل لديك خبرة في عالم السياحة: نعم\\لا. تفاصيل:",
                        "action": "احفظ وانتقل للدفع",
                        "cycleChoose": "أرغب في الانضمام لدورة",
                        "radioSelection": "الراغبون في الاندماج بعد الدورة ك",
                        "independent": "مستقل",
                        "employee": "موظف",
                        "onSuccess": "تم حفظ بياناتك بنجاح، يتم الآن تحويلك للدفع",
                        "required": "يجب إدخال حقل مطلوب!"
                    }
                },
                "header": {
                    "title": "دورة وكلاء ومسوقي السياحة",
                    "links": [
                        {
                            "text": "دورتنا",
                            "url": "course-info"
                        },
                        {
                            "text": "خبراؤنا",
                            "url": "course-experts"
                        },
                        {
                            "text": "التسجيل للتدريب",
                            "url": "training-register-form"
                        }
                    ]
                }
            },
            "courseLandingPage": {
                "title": "دورة وكلاء ومسوقي السياحة",
                "paymentForm": {
                    "welcome": "سعداء لاهتمامك بالانتقال إلى الدفع",
                    "title": "الدفع مقابل دورة وكلاء السفر",
                    "errorMessageToRetry": "محاولة رقم {retryNum} - فشل الدفع مع شركة البطاقة الائتمانية، يرجى إعادة إدخال تفاصيل البطاقة الائتمانية أو استخدام بطاقة أخرى. الموقع يقبل بطاقات VISA و MASTERCARD فقط.",
                    "cycle": "دورة"
                },
                "sections": {
                    "section1": {
                        "title": "أهداف الدورة",
                        "content1": "تدريب مهني من قادة صناعة السياحة",
                        "content2": "بدء العمل بعد انتهاء الدورة",
                        "content3": "الدعم والمرافقة المهنية من SKY 360",
                    },
                    "section2": {
                        "title": "أهداف الدورة",
                        "content": {
                            "c1": "توفير المعرفة الأساسية في عالم السياحة",
                            "c2": "تحديد الفرص والاتجاهات في عالم السياحة",
                            "c3": "إنشاء التميز في سوق تنافسي",
                            "c4": "تزويد الأدوات في المبيعات، الخدمة، الاستراتيجية التسويقية",
                            "c5": "توفير المعرفة الأساسية في الترويج المدفوع والعضوي",
                            "c6": "تعلم نظام SKY360 بالتعاون مع ALP",
                            "c7": "نظام لبيع حزم العطلات والرحلات الجوية من الشركات الجملة الكبرى في إسرائيل",
                        },
                    },
                    "section3": {
                        "title": "دورة وكلاء ومسوقي السياحة",
                        "lesson": "درس",
                        "content": {
                            "c1": {
                                "t": "معرفة عالم السياحة",
                                "c": "درس 1",
                            },
                            "c2": {
                                "t": "تحليل الجماهير المستهدفة وإنشاء الشرائح",
                                "c": "درس 2",
                            },
                            "c3": {
                                "t": "مطابقة وجهات العطلات للجمهور المستهدف",
                                "c": "درس 3",
                            },
                            "c4-5": {
                                "t": "التميز، تعظيم الأرباح في سوق تنافسي",
                                "c": "درس 4-5",
                            },
                            "c6": {
                                "t": "الاستراتيجية التسويقية",
                                "c": "درس 6",
                            },
                            "c7": {
                                "t": "البيع من خلال الخدمة",
                                "c": "درس 7",
                            },
                            "c8": {
                                "t": "التسويق في عالم السياحة",
                                "c": "درس 8",
                            },
                            "c9": {
                                "t": "الشبكات الاجتماعية",
                                "c": "درس 9",
                            },
                            "c10": {
                                "t": "إنشاء حملة مدفوعة، فيديوهات وتصميم جرافيك",
                                "c": "درس 10",
                            },
                            "c11": {
                                "t": "الجوانب القانونية والضريبة لوكيل السفر",
                                "c": "درس 11",
                            },
                            "c12": {
                                "t": "معرفة نظام الحجز لوكلاء السفر AMADEUS ALP",
                                "c": "درس 12",
                            },
                            "c13-14": {
                                "t": "معرفة نظام SKY 360 لمسوقي السياحة",
                                "c": "درس 13-14",
                            },
                            "c15": {
                                "t": "التحضير لمشروع التخرج",
                                "c": "درس 15",
                            },
                            "c16": {
                                "t": "عرض مشاريع التخرج",
                                "c": "درس 16",
                            },
                        },
                    }
                },
                "header": {
                    "title": "دورة وكلاء ومسوقي السياحة",
                    "links": [
                        {
                            "text": "للعمل في مجال السياحة",
                            "url": ""
                        },
                        {
                            "text": "دورتنا",
                            "url": "course-info"
                        },
                        {
                            "text": "خبراؤنا",
                            "url": "course-experts"
                        },
                        {
                            "text": "التسجيل للدورة",
                            "url": "course-register-form"
                        },
                    ],
                },
                "courseExperts": {
                    "title": "خبراؤنا",
                    "titleSlider": "بمشاركة خبراء شركات السياحة في الاقتصاد",
                    "experts": [
                        {
                            "title": "شركة سياحة جملة متخصصة في رحلات النيش والعائلات",
                            "img": "family_break.png"
                        },
                        {
                            "title": "شركة سياحة جملة من الرائدة في السوق الإسرائيلي",
                            "img": "mona_tours.png"
                        },
                        {
                            "title": "شركة طيران إسرائيلية وسياحة جملة من بين الأكبر في القطاع",
                            "img": "arkia.png"
                        },
                        {
                            "title": "نظام الحجز لشراء الرحلات الجوية وحزم العطلات لوكلاء السفر في إسرائيل",
                            "img": "alp.png"
                        },
                        {
                            "title": "الشركة الكبرى في إسرائيل لحزم الرياضة والعروض",
                            "img": "lord_tickets.png"
                        },
                        {
                            "title": "البيت لوكلاء السياحة في إسرائيل",
                            "img": "sky_360.png"
                        }
                    ]
                },
                "finishSuccessfully": {
                    "title": "للمتخرجين بنجاح - مكافأة بقيمة 5,000 شيكل",
                    "benefits": [
                        {
                            "title": "سيتم منح شهادة وكيل ومسوق سياحة من SKY360",
                            "img": "certification.png"
                        },
                        {
                            "title": "القصص الراوية العملية <br> محاضرات من <br> جال تسحيك <br> مؤسس كلية النتائج",
                            "img": "check_mark.png"
                        },
                        {
                            "title": "3 أشهر تجربة مجانية في نظام الحجز SKY360",
                            "img": "check_mark.png"
                        },
                        {
                            "title": "مرافقة مستمرة مع فريق الخبراء في السنة الأولى حتى النجاح",
                            "img": "check_mark.png"
                        },
                        {
                            "title": "للمناسبين والمهتمين دمج كموظفين مع شروط ممتازة في الشركات الرائدة في الاقتصاد",
                            "img": "check_mark.png"
                        },
                    ],
                },
                "courseDetails": {
                    "location": {
                        "title": "مكان الدراسة",
                        "content": "دورة عبر الإنترنت",
                    },
                    "structure": {
                        "title": "هيكل الدورة",
                        "semiTitle": "70 ساعة تعليمية",
                        "content": {
                            "c1": "مسار مسائي أو صباحي حسب الاختيار: مرتين في الأسبوع 16 لقاءً",
                            "c2": "في نهاية كل درس سيتم إرسال درس مسجل للمشاركين",
                            "c3": "كجزء من إكمال الدورة، سيُطلب من المشاركين تنفيذ مهام ومشروع تخرج",
                        },
                        "bonus": {
                            "title": "مكافأة",
                            "content1": "تدريب كامل على نظام ALP",
                            "content2": "نظام الحجز لوكلاء السفر في إسرائيل",
                        },
                    },
                    "duty": {
                        "title": "ما هو مطلوب؟",
                        "content": "المشاركة بانتظام في المحاضرات، تنفيذ المهام وتقديم مشروع التخرج",
                    },
                    "promoteSection": {
                        "t1": "للعمل في عالم السياحة!",
                        "t2": "تعالوا للعمل في عالم السياحة مع دعم أفضل الخبراء!",
                    }
                },
                "forWho": {
                    "title": "لمن البرنامج؟",
                    "content": {
                        "c1": "للراغبين في تحقيق دخل إضافي أو رئيسي في عالم السياحة",
                        "c2": "للراغبين في الاندماج كموظفين في الشركات الكبرى في السوق الإسرائيلي",
                        "c3": "وكلاء السفر الذين يرغبون في التدريب المهني والتخصص في عالم السياحة"
                    }
                },
                "finish": {
                    "title": "للمتخرجين بنجاح - مكافأة بقيمة 5,000 شيكل",
                    "content": {
                        "c1": "سيتم منح \"مسوق سياحة\" من سكاي جروب",
                        "c2": "درس مكافأة من عالم الـ NLP كيفية تغيير طريقة تفكيركم",
                        "c3": "الأهم - موقع سياحي تجاري يمكنكم البدء بالعمل عليه فورًا"
                    },
                    "registerNow": "سجل الآن",
                },
                "payment": {
                    "title": "سعر الدورة",
                    "shah": "شيكل",
                    "content1": "يمكن تقسيط الدفع حتى 8 أقساط",
                    "content2": "بدون فوائد بالبطاقة الائتمانية أو الدفع نقدًا عن طريق التحويل البنكي",
                    "registerNow": "سجل الآن!",
                    "form": {
                        "title": "سجل الآن!",
                        "full_name": "الاسم الكامل",
                        "email": "البريد الإلكتروني",
                        "phone": "الهاتف",
                        "birthday": "تاريخ الميلاد",
                        "moreInfo": "هل لديك خبرة في عالم السياحة: نعم\\لا. التفاصيل:",
                        "action": "إرسال",
                        "cycleChoose": "مهتم بالانضمام إلى الدورة",
                        "radioSelection": "هل ترغب في الانضمام بعد الدورة ك",
                        "independent": "مستقل",
                        "employee": "موظف",
                        "onSuccess": "تم إرسال معلوماتك بنجاح، سيتصل بك مندوب قريبًا",
                        "required": "يجب ملء الحقل المطلوب!"
                    }
                }
            },
            "private-flights":{
                            "title": "טיסות פרטיות מנמל תעופה חיפה",
                            "footer":{
                                'text':"כל הזכויות שמורות לsky360-. אין להשתמש בחומרי ותוכן האתר ללא אישור."
                            },
                            "destinations":{
                                'title':"טיסות פרטיות מנמל תעופה חיפה ליעדים",
                                'destinations':[{'text':"טורקיה", 'logo':"turkey.png"}, {'text':"איי יוון", logo:"greece.png"}, {'text':"קפריסין", logo:"cyprus.png"}]
                            },
                            "forWhom":{
                                'title':"למי השירות מתאים?",
                                'destinations':[{'text':"אנשי  עסקים", 'logo':"suitcase.png"}, {'text':"קבוצות כדורגל", logo:"football.png"}, {'text':"קבוצות כדורסל", logo:"basketball.png"}]
                            },
                            'aboutUs':{
                                'title':"על הפעילות שלנו",
                                'content':`טקסט פרטיות טקסט כאן על הפעילות שלנו בטיסות פרטיות טקסט כאן`
                            },
                            'contactUs':{
                                'title1':"לפרטים נוספים מלאו את הטופס",
                                'title2':"ואחד מנציגינו יחזור אליכם עם הצעת מחיר ופרטים נוספים בהרחבה.",
                                'form':{
                                        'email':'דוא״ל',
                                        'phone':'טלפון',
                                        'fullName':'שם מלא',
                                        'details':"פירוט הבקשה",
                                        'button':"שלח",
                                }
                            },
                            "benefits":[
                                    {
                                        text:"תהליך בידוק מהיר",
                                        logo:"quickCheck.png"
                                    },
                                    {
                                        text:"הגעה שעה לפני הטיסה",
                                        logo:"checkbox.png"
                                    },
                                    {
                                        text:"עד 37 מקומות בטיסה",
                                        logo:"sort.png"
                                    }
                            ]
            },
            "shoppingCartTranslations": {
                "attractionPriceDescription": "سعر الجذب يعتمد على التاريخ، اختيار الخيارات، وعدد التذاكر",
                "selectedDate": "التاريخ المحدد",
                "selectADate": "اختر تاريخًا",
                "availableDatesBetween": "التواريخ المتاحة بين",
                "Options": "خيارات",
                "Text": "نص",
                "Ticket type": "نوع التذكرة",
                "Start Time": "وقت البدء",
                "Pick Up Location": "موقع الالتقاء",
                "Custom Pick Up Location": "موقع الالتقاء المخصص",
                "Drop Off Location": "موقع النزول",
                "Custom Drop Off Location": "موقع النزول المخصص",
                "manageBaggage": "إدارة الأمتعة",
                "youCanAddGuestsOnlyUpToTheAvailableSeats": "يمكنك إضافة الضيوف فقط حتى المقاعد المتاحة",
                "shoppingCartButtonTooltip": "اختر التكوين وأضفه إلى الطلب",
                "totalPrice": "إجمالي الدفع",
                "proceedToPayment": "المتابعة إلى الدفع",
                "placeOrder": "المتابعة للتسجيل",
                "NoHotelWasChosenForTheVacation": "لم يتم اختيار فندق للعطلة",
                "betweenDates": "بين التواريخ",
                "packageInformation": "تفاصيل الحزمة",
                "rooms": "تفاصيل الغرف",
                "transfers": "الانتقالات",
                "reg_transfers": "الانتقالات/المواصلات من وإلى الفندق",
                "shuttle_transfers": "النقل من الحدود إلى الفندق والعودة",
                "flights": "الرحلات الجوية",
                "activities": "الأنشطة",
                "details": "تفاصيل",
                "shoppingCart": "عربة التسوق",
                "package": "الإقامة في الفندق",
                "nights": "ليالٍ",
                "inHotel": "في الفندق",
                "apply": "تأكيد",
                "promoCode": "رمز القسيمة",
                "yourShoppingCart": "عربة التسوق الخاصة بك",
                "isEmpty": "لا تزال فارغة...",
                "myVacation": "عطلتي",
                "showAll": "عرض الكل »",
                "beforeDiscount": "الإجمالي قبل الخصم -",
                "agentCommission": "عمولة الوكيل -",
                "agentCommissionNet": "عمولة الوكيل (الدفع الصافي) -",
                "discountFromCode": "خصم الكود -",
                "affiliateWith": "بالتعاون مع -",
                "tpId": "معرف الطلب -",
                "chargeTypeFullCC": "الدفع الكامل - يشمل العمولة",
                "chargeTypeNetCC": "دفع صافي",
                "chargeTypeCash": "عملية نقدية",
                "agentCommissionPrec": "نسبة عمولة الوكيل",
                "priceOff": "خصم السعر",
                "percentageOff": "نسبة الخصم",
                "selectedItems": "العناصر المحددة",
                "viewProduct": "عرض المنتج",
                "infantAdditionalPrice": "سعر إضافي للرضيع",
                "netPriceWarning": "السعر الصافي، الرجاء إضافة الربح!",
                "copyOrderLink": "نسخ الرابط",
                "createOrder": "حفظ العرض",
                "updateCommission": "حفظ",
                "commissionNotSaved": "الرجاء النقر للحفظ!",
                "clearCart": "انقر لمسح العربة",
                "assignCustomer": "تعيين العميل",
                "firstName": "الاسم الأول",
                "lastName": "اسم العائلة",
                "phone": "الهاتف",
                "comment": "تعليق",
                "email": "البريد الإلكتروني",
                "wrongEmailFormat": "صيغة البريد الإلكتروني غير صحيحة",
                "saveCustomer": "حفظ العميل",
                "updateCustomerTitle": "تحديث العميل",
                "createCustomerTitle": "إنشاء عميل جديد",
                "sendByWhatsApp": "إرسال عبر WhatsApp",
                "copyText": "نسخ النص",
                "sendBySMS": "إرسال عبر SMS",
                "findQuoteFor": "إرسال عرض عبر",
                "linkIsGive": "العرض نشط",
                "dates": "التواريخ",
                "hotelName": "اسم الفندق",
                "flightPrice": "سعر الرحلة",
                "suitcase": "حقيبة",
                "trolly": "عربة",
                "passengers": "الركاب",
                "totalAgentComm": "إجمالي عمولة الوكيل",
                "agentOptions": "خيارات الوكيل",
                "compareDisableForAgent": "مقارنة الأسعار متاحة عند وجود عرضين في العربة",
                "createNewUser": "إنشاء عرض جديد",
                "comparePrice": "مقارنة الأسعار",
                "send": "إرسال العرض",
                "profitPercentageChange": "تغيير الربح",
                "or": "أو",
                "approval": "الموافقة",
                "profitPerPerson": "الربح لكل شخص",
                "grossProfitPercentage": "نسبة الربح الإجمالية",
                "actionNotAllowed": "الإجراء غير مسموح",
                "orderIsAlreadySaved": "تم حفظ الطلب بالفعل ولا يمكن إضافة منتجات إضافية",
                "clickToClear": "انقر لمسح العربة",
                "roomsByRequestSubjectOfHotelAvailability": "غير قادر على إضافة نفس نوع الغرفة، يرجى اختيار نوع مختلف",
                "roomsByRequestSubjectOfHotelAvailabilityTitle": "فشل في إضافة الغرفة",
                "attractionAlreadyAdded": "غير قادر على إضافة نفس نوع الجذب السياحي، يرجى اختيار نوع مختلف",
                "failedToAddAttraction": "فشل في إضافة الجذب السياحي",
                "noAvailabilityOnThisDates": "لا يوجد توافر للتواريخ المحددة",
                "attractionSearchFailed": "فشل البحث عن الجذب السياحي",
                "cannotSearchForThePastDates": "لا يمكن البحث عن تواريخ سابقة",
                "searchOtherForDates": "لا يوجد توافر، يرجى البحث عن تواريخ أخرى متاحة",
                "adult": "بالغ",
                "child": "طفل",
                "infant": "رضيع",
                "perPerson": "لكل شخص",
                "perVehicle": "لكل مركبة",
                "group": "مجموعة",
                "family": "عائلة",
                "perEquipment": "لكل معدات",
                "perBoat": "لكل قارب",
                "none": "لا شيء"
            },
        }
    }
};

export default translationsAr;